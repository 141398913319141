// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-about-js": () => import("./../../../src/pages/about/About.js" /* webpackChunkName: "component---src-pages-about-about-js" */),
  "component---src-pages-connect-connect-js": () => import("./../../../src/pages/connect/Connect.js" /* webpackChunkName: "component---src-pages-connect-connect-js" */),
  "component---src-pages-cv-cv-js": () => import("./../../../src/pages/cv/Cv.js" /* webpackChunkName: "component---src-pages-cv-cv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intro-intro-js": () => import("./../../../src/pages/intro/Intro.js" /* webpackChunkName: "component---src-pages-intro-intro-js" */),
  "component---src-pages-main-main-js": () => import("./../../../src/pages/main/Main.js" /* webpackChunkName: "component---src-pages-main-main-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-projects-projects-js": () => import("./../../../src/pages/projects/Projects.js" /* webpackChunkName: "component---src-pages-projects-projects-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

